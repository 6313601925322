import TotalCardSms from "./TotalCardSms/TotalCardSms";
import DeliveryStatusSms from "./DeliveryStatusSms/DeliveryStatusSms";

import styles from "./TotalCardsSms.module.css";

const TotalCardsSms = ({filter, filterValues, handleTotalUpdate}) => {
  return (
    <div className={styles['dashboard-card-container']}> 
         <TotalCardSms filter={filter} filterValues={filterValues} onTotalUpdate={(total) => handleTotalUpdate("sms", total)}/>
         <DeliveryStatusSms filter={filter} filterValues={filterValues}/>
    </div>
  );
};

export default TotalCardsSms;
