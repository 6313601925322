import React, { useState, useEffect, useContext } from "react";
import { Alert, Col, Form, Collapse, Select, Input, Button, Modal, Row } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import styles from "./SubFilter.module.css";
import ModalConfirmation from "../../../ModalConfirmation/ModalConfirmation";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrash } from '@fortawesome/pro-solid-svg-icons';
import ButtonYp from '../../../Button/Button';
import { useNavigate } from "react-router";
import DataApi from "../../../../Api/DataApi";
import ReportApi from "../../../../Api/ReportApi";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST, ROLENEWDASBOARD, CONSTANTUSERTYPE } from "../../../../utils/const";
import { AuthContext } from "../../../../contexts/authRedirect";
//import { getTotalVoiceData } from "../CompanyHome/TotalVoice";


const { Panel } = Collapse;
const { Option } = Select;

const FilterPanel = ({ onSaveReport, shouldShowCollapse, channelSelected, filter, onFilterChange, totals, total }) => {
  const [filters, setFilters] = useState([{ field: "", operator: "", value: "" }]);
  const [selectedFields, setSelectedFields] = useState([]); // List of selected options
  const [accounts, setAccounts] = useState([]); // State to store account data from API
  const [countries, setCountries] = useState([]);
  const [methods, setMethods] = useState([]);
  const [deliveryStatus, setDeliveryStatus] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [showReportNameValidation, setShowReportNameValidation] = useState(false);
  const [reportName, setReportName] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { getDashboardFilters } = DataApi();
  const { auth } = useContext(AuthContext);
  const [selectedChannel, setSelectedChannel] = useState(CHANELLIST.VOICE); 
  const [chartData, setChartData] = useState([]); // Estado para los datos del gráfico
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);
  const [isDoneClicked, setIsDoneClicked] = useState(false); //Estado para manejar el click del Done
  const { getReportDashboard } = ReportApi();
  const [t] = useTranslation("home-company");

  const availableFields = [
    "Accounts",
    "Country",
    "Delivery Status",
    "Campaign",
    "Method",
  ];


  // Llamada al API para obtener las cuentas cuando se seleccione un canal
  useEffect(() => {
    const fetchAccounts = async () => {
      console.log("Valor de channelSelected:", channelSelected);
      setFilters((prevFilters) =>
        prevFilters.map((filter) => ({
          ...filter,
          value: "", // Limpia únicamente el campo 'value'
        }))
      );
      // Verificamos que filter esté definido y que tenga un id
      if (channelSelected) {
        //console.log('prueba'); // Verifica que esté entrando en este bloque
        if (!filter || !filter.id) {
          //console.error("El filtro no está definido correctamente:", filter);
          return; // Detén la ejecución si no hay un filtro válido
        }
  
        // Imprime el filtro y el accountId para confirmar que sean correctos
        console.log("filter.id:", filter.id);
        console.log("CONSTANTS.ACCOUNTDEFAULT.accountId:", CONSTANTS.ACCOUNTDEFAULT.accountId);
  
        console.log("Filtro válido, llamando a la API...");
  
        // Construcción del objeto data dependiendo de la comparación
        let data = {
          CompanyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
          AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
          //ChannelId:  selectedChannel.key,
          ChannelId: filter.channel === "SMS" ? CHANELLIST.SMS.key : CHANELLIST.VOICE.key, 
        };
  
        console.log("Datos enviados a la API:", data); // Verifica los datos que se están enviando a la API
  
        // Establece el tipo de usuario dependiendo de la condición
        //let userType = filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? CONSTANTUSERTYPE.COMPANY.key : CONSTANTUSERTYPE.ACCOUNT.key;
        const userType = ROLENEWDASBOARD.COMPANY_ADMIN.key
        console.log("Tipo de usuario:", userType); // Verifica el tipo de usuario
  
        // Llamada a la API
        let response = await getDashboardFilters(
          filter.channel === "SMS" ? CHANELLIST.SMS.value : CHANELLIST.VOICE.value, 
          userType, 
          filter.time, 
          data);
        
        console.log("Respuesta de la API:", response); // Imprime la respuesta de la API
  
        if (response && response.accounts) {
          setAccounts(response.accounts); // Actualiza las cuentas con la respuesta de la API
        } else {
          console.error("No se recibieron compañías válidas en la respuesta de la API");
        }
      } else {
        //console.error("El valor de channelSelected es inválido");
      }
      
    };
  
    fetchAccounts();
  }, [channelSelected, filter, auth]);

  useEffect(() => {
    const fetchCountries = async () => {
      if (!channelSelected) {
        //console.error("El valor de channelSelected es inválido");
        return;
      }
  
      if (!filter || !filter.id) {
        //console.error("El filtro no está definido correctamente:", filter);
        return;
      }
  
      // Construir los datos que se envían a la API para obtener los países
      const data = {
        CompanyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
        AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
        ChannelId: selectedChannel.key,
      };

       // Definir el tipo de usuario basado en la condición
      let userType = filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId
      ? CONSTANTUSERTYPE.COMPANY.key
      : CONSTANTUSERTYPE.ACCOUNT.key;

  
      console.log("Datos enviados a la API para países:", data);
  
      // Llamada a la API para obtener los países
      const response = await getDashboardFilters("countries", userType, filter.time, data);
  
      console.log("Respuesta de la API para países:", response);
  
      if (response && response.countries) {
        setCountries(response.countries); // Actualiza el estado de países
      } else {
        console.error("No se recibieron países válidos en la respuesta de la API");
      }
    };
  
    fetchCountries();
  }, [channelSelected, filter, auth]);

  useEffect(() => {
    const fetchDeliveryStatus = async () => {
      if (!channelSelected || !filter || !filter.id) {
        //console.error("El valor de channelSelected o el filtro son inválidos");
        return;
      }
  
      const data = {
        CompanyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
        AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
        ChannelId: selectedChannel.key,
      };
        // Definir el tipo de usuario basado en la condición
        let userType = filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId
        ? CONSTANTUSERTYPE.COMPANY.key
        : CONSTANTUSERTYPE.ACCOUNT.key;
  
      const response = await getDashboardFilters("deliveryStatus", userType, filter.time, data);
  
     

      // Asignar el valor del total dependiendo del canal seleccionado
      let totalValue = filter.channel === "SMS" 
      ? response.totalMessagesSMS 
      : response.totalMessagesVoice;

      return { totalValue };
  
      if (response && response.deliveryStatus) {
        setDeliveryStatus(response.deliveryStatus); // Actualiza el estado de estatus de entrega
      } else {
        console.error("No se recibieron estatus de entrega válidos en la respuesta de la API");
      }
    };
  
    fetchDeliveryStatus();
  }, [channelSelected, filter, auth]);

  useEffect(() => {
    const fetchCampaigns = async () => {
      if (!channelSelected || !filter || !filter.id) {
        //console.error("El valor de channelSelected o el filtro son inválidos");
        return;
      }
  
      const data = {
        CompanyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
        AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
        ChannelId: selectedChannel.key,
      };

       // Definir el tipo de usuario basado en la condición
       let userType = filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId
       ? CONSTANTUSERTYPE.COMPANY.key
       : CONSTANTUSERTYPE.ACCOUNT.key;
  
      const response = await getDashboardFilters("campaigns", userType, filter.time, data);
  
     
  
      if (response && response.campaigns) {
        setCampaigns(response.campaigns); // Actualiza el estado de campañas
      } else {
        console.error("No se recibieron campañas válidas en la respuesta de la API");
      }
    };
  
    fetchCampaigns();
  }, [channelSelected, filter, auth]);
  

  useEffect(() => {
    const fetchMethods = async () => {
      if (!channelSelected || !filter || !filter.id) {
        //console.error("El valor de channelSelected o el filtro son inválidos");
        return;
      }
  
      // Datos para la API
      const data = {
        CompanyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
        AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
        ChannelId: selectedChannel.key,
      };

       // Definir el tipo de usuario basado en la condición
       let userType = filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId
       ? CONSTANTUSERTYPE.COMPANY.key
       : CONSTANTUSERTYPE.ACCOUNT.key;
  
      const response = await getDashboardFilters("methods", userType, filter.time, data);
  
      console.log("Respuesta de la API para métodos:", response);
  
      if (response && response.methods) {
        setMethods(response.methods); // Actualiza el estado de métodos
      } else {
        console.error("No se recibieron métodos válidos en la respuesta de la API");
      }
    };
  
    fetchMethods();
  }, [channelSelected, filter, auth]);
  
    
  

  /*const exportReport = (format) => {
    const data = filters.map(
      (filter) => `${filter.field},${filter.operator},${filter.value}`
    );
    console.log("Totales recibidos:", totals);

      // Determinar los totales según el canal seleccionado
    const selectedChannel = channelSelected.key; // `channelSelected` debería estar disponible en el scope
    let reportData = "";

    if (selectedChannel === CHANELLIST.SMS.key) {
      // Canal SMS
      reportData = `Metric,Total\nSMS Use,${totals.sms}`;
    } else if (selectedChannel === CHANELLIST.VOICE.key) {
      // Canal Voz
      reportData = `Metric,Total\nVoice Use,${totals.voice}`;
    } else if (selectedChannel === CHANELLIST.DEFAULT.key) {
      // Canal Overview: combinación de SMS y Voz
      reportData = `Metric,Total\nSMS Use,${totals.sms}\nVoice Use,${totals.voice}`;
    } else {
      // En caso de que no se detecte un canal válido
      reportData = `Metric,Total\nNo data available,0`;
    }
  

    const csvContent = `data:text/${format};charset=utf-8,${reportData}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${reportName}.${format === "csv" ? "csv" : "xlsx"}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    navigate("Campaigns/Reports");
  };*/

  

  /*const exportReport = async (format) => {
    // Construir los datos necesarios para la llamada a la API
    const requestData = {
      StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key && filter.date?.[0] ? filter.date[0].toISOString() : null,
      EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key && filter.date?.[1] ? filter.date[1].toISOString() : null,
      Accounts: filters.filter((f) => f.field === "Accounts").map((f) => f.value).flat(),
      ChannelId: filter.channel === "SMS" ? CHANELLIST.SMS.key : CHANELLIST.VOICE.key,
      Email: auth.email,
      FileName: reportName || "Reporte",
      Extension: format === "csv" ? 1 : 2,
      CompanyId: auth.companyId,
      AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
    };
  
    try {
      console.log("Datos enviados a la API:", requestData);
  
      // Llamar a la API para obtener el reporte
      const response = await getReportDashboard(       
        ROLENEWDASBOARD.COMPANY_ADMIN.key,
        filter.time,
        requestData,
      );
  
      console.log("Datos recibidos de la API:", response);
  
      // Verificar si la API devolvió una URL para el archivo
      if (response && response.downloadUrl) {
        const link = document.createElement("a");
        link.href = response.downloadUrl;
        link.setAttribute("download", `${reportName || "Reporte"}.${format === "csv" ? "csv" : "xlsx"}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("No se recibió una URL válida para descargar el archivo.");
      }
    } catch (error) {
      console.error("Error al llamar a la API para exportar el reporte:", error);
    }
  };*/

  /*const exportReport = async (format) => {
    const report = {
      StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null,     
      Accounts: filters.filter((f) => f.field === "Accounts").map((f) => f.value).flat() || [],
      ChannelId: filter.channel === "SMS" ? CHANELLIST.SMS.key : CHANELLIST.VOICE.key,
      Email: auth.email || "default@example.com",
      FileName: reportName || "Reporte",
      Extension: format === "csv" ? 1 : 2,
      CompanyId: auth.companyId || null,
      AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
    };
    
  
    console.log("Datos enviados a la API:", report);
  
    try {
      const response = await getReportDashboard(
        ROLENEWDASBOARD.COMPANY_ADMIN.key,
        filter.time,
        null, //Si no hay data, lo uso como null
        report
      );
  
      console.log("Respuesta de la API:", response);
  
      if (response && response.downloadUrl) {
        const link = document.createElement("a");
        link.href = response.downloadUrl;
        link.setAttribute("download", `${reportName || "Reporte"}.${format === "csv" ? "csv" : "xlsx"}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("No se recibió una URL válida para descargar el archivo.");
      }
    } catch (error) {
      console.error("Error al llamar a la API para exportar el reporte:", error);
    }
  };*/
  

  const addFilter = () => {
    const newFilter = { id: filters.length + 1, field: "", operator: "", value: "" };
    setFilters([...filters, newFilter]);
  };

  
  const removeFilter = (id) => {
    const removedField = filters.find((filter) => filter.id === id)?.field;
    setSelectedFields(selectedFields.filter((field) => field !== removedField));
    setFilters(filters.filter((filter) => filter.id !== id));
  };

  // const handleFieldChange = (value, id) => {
  //   const updatedFilters = filters.map((filter) => {
  //     if (filter.id === id) {
  //       if (filter.field) {
  //         setSelectedFields(selectedFields.filter((field) => field !== filter.field));
  //       }
  //       return { ...filter, field: value, id:filter.id };
  //     }
  //     return filter;
  //   });

  //   setFilters(updatedFilters);
  //   setSelectedFields([...selectedFields, value]);
  // };

  const handleFieldChange = (value, id) => {
    const updatedFilters = filters.map((filter) => {
      if (filter.id === id) {
        if (filter.field) {
          // Restaura el campo previamente seleccionado en `selectedFields`
          setSelectedFields((prevSelected) =>
            prevSelected.filter((field) => field !== filter.field)
          );
        }
  
        // Actualiza el filtro con el nuevo campo
        return { ...filter, field: value, value: [] };
      }
      return filter;
    });
  
    setFilters(updatedFilters);
  
    // Agrega el nuevo campo a `selectedFields`
    setSelectedFields((prevSelected) => [...prevSelected, value]);
  };
  

 

  // Modal functions
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  /*const handleOkReport = (format) => {
    if (!reportName) {
      setShowReportNameValidation(true);
      return;
    }
    exportReport(format);
    handleCancelReport();    
  }*/

    const handleOkReport = async (format) => {
      if (!reportName) {
        setShowReportNameValidation(true);
        return;
      }

        // Datos para la API
        const data = {
          CompanyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
          AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
          ChannelId: selectedChannel.key,
        };
  
         // Definir el tipo de usuario basado en la condición
         let userType = filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId
         ? CONSTANTUSERTYPE.COMPANY.key
         : CONSTANTUSERTYPE.ACCOUNT.key;
    
        let report = {     
          StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key && filter.date?.[0] ? filter.date[0].toISOString() : null,
          EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key && filter.date?.[1] ? filter.date[1].toISOString() : null,
          Accounts: filters.filter((f) => f.field === "Accounts").map((f) => f.value).flat(),
          ChannelId: filter.channel === "SMS" ? CHANELLIST.SMS.key : CHANELLIST.VOICE.key,        
          FileName: reportName || "Default Report Name",
          Extension: format === "csv" ? 1 : 2,
          CompanyId: auth.companyId,
          AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
          Email: auth.userName,
          Filters: filters.map((filter) => ({
            field: filter.field, // Campo seleccionado por el usuario (e.g., "Companies", "Country")
            operator: filter.operator, // Operador seleccionado (e.g., "Equal")
            value: Array.isArray(filter.value) ? filter.value : [filter.value], // Valor(es) seleccionados
          })),
        };
    
      try {
        // Verifica si el reporte existe en el dashboard
        //const response = await getReportDashboard(auth.userName);
        // Llamada a la API
        let response = await getReportDashboard(
          //filter.channel === "SMS" ? CHANELLIST.SMS.value : CHANELLIST.VOICE.value, 
          userType, 
          filter.time, 
          report);

        if (response && response.reports) {
          const existingReport = response.reports.find((report) => report.name === reportName);
    
          if (existingReport) {
            console.log("Reporte ya existe:", existingReport);
          } else {
            console.log("El reporte no existe, se creará uno nuevo.");
          }
        }
    
        // Redirige a la página de reportes
        navigate("/campaigns/reports");
    
      } catch (error) {
        console.error("Error al obtener los reportes del dashboard:", error);
      } finally {
        // Limpia el estado del modal
        setIsModalVisible(false);
        setShowReportNameValidation(false);
        setReportName(""); // Limpia el nombre
        form.resetFields();
      }
    };
    

  const handleCancelReport = () => {
    setIsModalVisible(false);
    setShowReportNameValidation(false);
    setReportName("");
    form.resetFields();
  }

  const handleReportNameChange = (e) => {
    let value = e.target.value;
    if (value) {
      setShowReportNameValidation(false);
      setReportName(value);
    }
  }


// Función para manejar el cambio de valores
const handleValueChange = (value, id) => {
  const updatedValue = Array.isArray(value) ? value : [value];

  const updatedFilters = filters.map((filter) => {
    if (filter.id === id) {
      return { ...filter, value: updatedValue };
    }
    return filter;
  });
  setFilters(updatedFilters);
};

// Función para obtener los datos del gráfico según los filtros
  const fetchChartData = async (filters) => {
    try {
      const filterParams = filters.map((filter) => ({
        field: filter.field,
        operator: filter.operator,
        value: filter.value,
      }));

      const data = {
        filters: filterParams,
        channelId: selectedChannel.key,
      };

      console.log("Datos enviados para el gráfico:", data);

      const response = await getDashboardFilters("chartData", "user", filter.time, data);

      if (response && response.chartData) {
        setChartData(response.chartData);
      } else {
        console.error("No se recibieron datos válidos para el gráfico");
      }
    } catch (error) {
      console.error("Error al obtener los datos del gráfico:", error);
    }
  };

   // Función para manejar el clic en "Done" y mostrar los datos capturados en consola
   /*const handleDoneClick = () => {
    setIsDoneClicked(true); // Activa el estado de Done
    //getTotalVoiceData();
    console.log("Datos capturados hasta el momento:");
    console.log({
      filters,      
    });
    if (typeof onFilterChange === "function") {
      onFilterChange(filters); // Envía los filtros al padre
  } else {
      console.error("onFilterChange no está definido.");
  }
  };*/

   // Función para manejar el clic en "Done" y mostrar los datos capturados en consola
   const handleDoneClick = () => {
    setIsDoneClicked(true); // Activa el estado de Done
    //getTotalVoiceData();
    console.log("Datos capturados hasta el momento:");
    console.log({
      filters,
      /*selectedFields,
      channelSelected,
      filter*/
    });
    
      onFilterChange(filters); // Envía los filtros al padre
  
  };

  

 
  const [filterData, setFilterData] = useState({    
    countries: filters,
    method: '',
    deliveryStatus: '',
    campaign: '',
    account:'',
  });

// Función para manejar el clic en el botón "Aplicar filtros"
const handleUpdateChart = async () => {
  // Llamar a la función para obtener los datos del gráfico con los filtros seleccionados
  await fetchChartData(filters);
  setIsCollapseOpen(false);
};


 // Función para manejar el clic en "More Filters"
 const handleMoreFiltersClick = () => {
  setIsCollapseOpen(prevState => !prevState); // Abre el Collapse
};



  // Check if the button should be disabled
  const isAddButtonDisabled = filters.length >= 5;

  
 
  return (
    <div className={styles["filter-container"]}>
      {shouldShowCollapse && (
        <Collapse 
          defaultActiveKey={[]}
          //activeKey={isCollapseOpen ? ["1"] : []}         
          expandIconPosition="start"
          className={styles["custom-collapse"]}
        >
          <Panel
            header={t("subfilter.label-more")}
            key="1"
            className={styles["custom-panel"]}   
            onClick={handleMoreFiltersClick}      
          >
            <div className={styles["filter-grid"]}>
              {filters.map((filter,index) => (
                <div key={index} className={styles["filter-row"]}>
                  <Select
                    placeholder="Select"
                    style={{ width: "200px" }}
                    onChange={(value) => handleFieldChange(value, filter.id)}
                    value={filter.field || undefined}
                  >
                    {availableFields
                      .filter((field) => !selectedFields.includes(field)) // Exclude already selected fields
                      .map((field) => (
                        <Option key={field} value={field}>
                          {field}
                        </Option>
                      ))}
                  </Select>
                  <Select
                    placeholder="Operator"
                    style={{ width: "150px" }}
                    onChange={(value) => console.log(`Operator: ${value}`)}
                  >
                    <Option value="equal">Equal</Option>
                  </Select>
                  <div className="content-select-value">
                    <Select 
                     value={filter.value.length > 0 ? filter.value : undefined}                    
                      mode="multiple" // Enable multiple selection
                      placeholder="Value"
                      style={{ width: "200px" }}
                      //value={filter.value} // Bind the value to the selected values
                      onChange={(value) => handleValueChange(value, filter.id)} // Handle value changes
                    >
                      {/* Show accounts if "Accounts" field is selected */}
                      {filter.field === 'Accounts' && accounts.length > 0 &&
                          accounts.map((accounts) => (
                              <Option key={accounts.accountId} value={accounts.accountId}>
                                  {accounts.accountName}
                              </Option>
                          ))
                        }
                    
                      {filter.field === 'Country' && countries.length > 0 && 
                          countries.map((country) => {                          
                            return (
                              <Option key={country.prefix[0]} value={country.prefix[0]}>
                                {country.country}
                              </Option>
                            ); // Retornar el JSX aquí
                          })
                        }

                        {/* Mostrar estados de entrega si el campo es "Delivery Status" */}
                        {filter.field === 'Delivery Status' && deliveryStatus.length > 0 &&
                            deliveryStatus.map((status) => (
                                <Option key={status.deliveryStatusLabel} value={status.deliveryStatus}>
                                    {status.deliveryStatusLabel}
                                </Option>
                            ))
                        }

                        {/* Mostrar métodos si el campo es "Method" */}
                        {filter.field === 'Method' && methods.length > 0 &&
                            methods.map((method) => (
                                <Option key={method.methodTypeId} value={method.methodTypeId}>
                                    {method.methodType}
                                </Option>
                            ))
                        }
                        
                        {/* Mostrar métodos si el campo es "Campaigns" */}
                        {filter.field === 'Campaign' && campaigns.length > 0 &&
                            campaigns.map((campaign) => (
                                <Option key={campaign.campaignId} value={campaign.campaignId}>
                                    {campaign.campaignName}
                                </Option>
                            ))
                        }

                    
                    </Select>
                  </div>
                  <Button
                    icon={<FontAwesomeIcon icon={faTrash}/>}                  
                    danger
                    onClick={() => removeFilter(filter.id)}
                  />
                </div>
              ))}
            </div>
            <div className={styles["filter-button-end"]}>
              <Button
                type="dashed"
                icon={<PlusOutlined />}
                onClick={addFilter}
                disabled={isAddButtonDisabled}
                className={styles["button-add"]}
              >
                {t("subfilter.add-filter")}
              </Button>
              <Button type="primary"
              onClick={handleDoneClick} 
              className={styles["button-done"]}
              >
                {t("subfilter.done")}
              </Button>
            </div>
          </Panel>
        </Collapse>
      )}
       
      <div className={styles["content-buttons-modal"]}>
        <ButtonYp  className={styles["buttons-modal"]}
          type="link"
          onClick={() => setIsModalVisible(true)}
          icon={<FontAwesomeIcon icon={faDownload}/>}             
        >
          {t("modal-file.open-modal-csv")}
        </ButtonYp> 
        <ButtonYp  className={styles["buttons-modal"]} 
          type="link"
          onClick={() => setIsModalVisible(true)}
          icon={<FontAwesomeIcon icon={faDownload}/>}             
        >
          {t("modal-file.open-modal-xlsx")}
        </ButtonYp> 
      </div>                
        <Modal
        title={t("modal-file.label-title")}
        open={isModalVisible}
        onOk={() => handleOkReport("csv")}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form>
          <Form.Item
            label={t("modal-file.label-message")}
            extra={
              showReportNameValidation && (
                <Alert message={t("modal-file.label-message-error")} type="error" showIcon />
              )
            }
          >
            <Input value={reportName} onChange={handleReportNameChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default FilterPanel;
