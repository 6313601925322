import { useState, useEffect, useContext } from "react";
import styles from "./TotalChannels.module.css";
import { Spin } from "antd";
import { Line } from "@ant-design/charts";
import DataApi from "../../../../Api/DataApi";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST, CONSTANTUSERTYPE, ROLENEWDASBOARD } from "../../../../utils/const";
import { AuthContext } from "../../../../contexts/authRedirect";
import { useTranslation } from "react-i18next";
import { getGradientColor, purpleColor, magentaColor, magentaColorOpacity, orangeColor, smsgraphic, voicegraphic } from "../../../../utils/colorsCss";

const TotalChannels = ({filter, onTotalsUpdate}) => {
  const  { t, i18n } = useTranslation("home-company");
  const { getDashboardTotal } = DataApi();
  const { auth } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(200);

  useEffect(() => {
    setLoading(true);
    
    // Obtain and combine SMS and Voice data
    Promise.all([getTotalSmsData(), getTotalVoiceData()])
      .then(([smsData, voiceData]) => {
        setLoading(false);

        const combinedData = [...smsData.chartData, ...voiceData.chartData];
        setData(combinedData);

        const smsTotal = smsData.totalValue.reduce((acc, item) => acc + item.totalMessageDate, 0);
        const voiceTotal = voiceData.totalValue.reduce((acc, item) => acc + item.totalMessageDate, 0);
        setTotal(smsTotal + voiceTotal);
        //setTotal(smsData.totalValue + voiceData.totalValue);
        // Pasar los totales al componente padre
          if (typeof onTotalsUpdate === "function") {
          onTotalsUpdate({ sms: smsTotal, voice: voiceTotal });
        }
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [filter, i18n.language]);

  const getTotalSmsData = async () => {
    const data = {
      CompanyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
      AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
      //StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      //EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null,
    };
    
    
    const userType = ROLENEWDASBOARD.ADMIN.key
    //Lineas para manejar el rol
    // auth.role === ROLENEWDASBOARD.ADMIN.value
    // ? ROLENEWDASBOARD.ADMIN.key
    // : auth.role === ROLENEWDASBOARD.COMPANY_ADMIN.value
    //     ? ROLENEWDASBOARD.COMPANY_ADMIN.key
    //     : ROLENEWDASBOARD.USER.key;  
    const response = await getDashboardTotal( userType, filter.time, data);

    
    // Check if the response contains items and if there is data in totalMessageDate"
    const responseData = (response.itemsSms).map(x => {      
        
        return {
          type: "SMS",
          value: x.totalMessageDate || 0,  // If there is no value, set it to 0
          time: x.label,
        };      
  }).flat();
   

    return { chartData: responseData, totalValue: response.itemsSms };
  };

  const getTotalVoiceData = async () => {
    const data = {
      CompanyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
      AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
      //StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      //EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null,
    };

    const userType = ROLENEWDASBOARD.ADMIN.key
    //Lineas para manejar el rol
    // auth.role === ROLENEWDASBOARD.ADMIN.value
    // ? ROLENEWDASBOARD.ADMIN.key
    // : auth.role === ROLENEWDASBOARD.COMPANY_ADMIN.value
    //     ? ROLENEWDASBOARD.COMPANY_ADMIN.key
    //     : ROLENEWDASBOARD.USER.key;  
    const response = await getDashboardTotal( userType, filter.time, data);

  

      // Check if the response contains items and if there is data in totalMessageDate
  const responseData = (response.itemsVoice).map(x =>  {
      
      return {
        type: t("channel.voice"), 
        value: x.totalMessageDate || 0,  // If there is no value, set it to 0
        time: x.label,
      };    
}).flat();


    return { chartData: responseData, totalValue: response.itemsVoice };
  };


  //Valores quemados
  // Valores quemados
  /*useEffect(() => {
    setData([
      { type: "SMS", time: "2024-01", value: 100 },
      { type: "Voice", time: "2024-01", value: 80 },
      { type: "WhatsApp", time: "2024-01", value: 60 },
      { type: "RCS", time: "2024-01", value: 40 },
      { type: "SMS", time: "2024-02", value: 120 },
      { type: "Voice", time: "2024-02", value: 90 },
      { type: "WhatsApp", time: "2024-02", value: 70 },
      { type: "RCS", time: "2024-02", value: 50 },
      { type: "SMS", time: "2024-03", value: 150 },
      { type: "Voice", time: "2024-03", value: 100 },
      { type: "WhatsApp", time: "2024-03", value: 80 },
      { type: "RCS", time: "2024-03", value: 60 },
    ]);
    setLoading(false);
  }, []);*/


  const config = {
    data,
    xField: 'time',
    yField: 'value',
    seriesField: 'type',
    isStack: true,    
    colorField: 'type', 
    scale: {
      color: {
        range: [smsgraphic, voicegraphic],
      }
    },
    legend: {
      position: "top",
      flipPage: false,
    },    
  };

  return (
    <div className={styles['dashboard-total']}>
      <div className={styles['card-title-total']}>
        <p>{t("card-total.label-title")}</p>           
      </div>
      <div className={styles['dashboard-card-spinner']}>
        <Spin spinning={loading}>
          <div className={styles['dashboard-card-body']}>   
                   
            <Line height={300} {...config} />
          </div>
        </Spin>
      </div>
    </div>    
  );
};

export default TotalChannels;
