import { useContext, useState } from "react";
import CardCustom from "../../../CardCustom/CardCustom";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./TotalVoice.module.css";
import { useEffect } from "react";
import { Spin } from "antd";
import { Line } from "@ant-design/charts";
import { each, groupBy } from '@antv/util';
import { getGradientColor, purpleColor, violetColor, violetColorOpacity, notificationColor } from "../../../../utils/colorsCss";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../../../../utils/helpers";
import DataApi from "../../../../Api/DataApi";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST, CONSTANTUSERTYPE, ROLENEWDASBOARD} from "../../../../utils/const";
import { AuthContext } from "../../../../contexts/authRedirect";

const TotalVoice = ({filter, filterValues}) => {
  useEffect(() => {
    console.log("Valores recibidos en TotalSms:", filterValues);
}, [filterValues]); // Ejecutar cuando filterValues cambie

const [chartData, setChartData] = useState([]);
const [totalValue, setTotalValue] = useState(0);

  const [ t, i18n] = useTranslation("home-account");
  const {getDashboardChannels} = DataApi();
  const { auth } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
        setLoading(true);

        try {
            if (filterValues.length === 0) {
                console.log("Cargando datos totales sin filtros...");
                const { chartData, totalValue } = await getTotalVoiceData([]);
                setChartData(chartData);
                setTotalValue(totalValue);
            } else {
                console.log("Cargando datos con filtros:", filterValues);
                const { chartData, totalValue } = await getTotalVoiceData(filterValues);
                setChartData(chartData);
                setTotalValue(totalValue);
            }
        } catch (error) {
            console.error("Error al cargar los datos:", error);
        } finally {
            setLoading(false);
        }
    };

    fetchData();
}, [filterValues, filter, i18n.language]);  

  const getTotalVoiceData = async () => {
    let data = {
      companyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
      accountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
      ChannelId: CHANELLIST.VOICE.key,
      companies: filterValues.find(f => f.field === "Companies")?.value || [],
      campaigns: filterValues.find(f => f.field === "Campaign")?.value || [],
      countries: filterValues.find(f => f.field === "Country")?.value || [],
      methods: filterValues.find(f => f.field === "Method")?.value || [],
      deliveryStatus: filterValues.find(f => f.field === "Delivery Status")?.value || [],  
      //StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      //EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null
    }
    const userType = ROLENEWDASBOARD.COMPANY_ADMIN.key
    //let userType = filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? CONSTANTUSERTYPE.COMPANY.key : CONSTANTUSERTYPE.ACCOUNT.key;
    let response = await getDashboardChannels(CHANELLIST.VOICE.value, userType, filter.time, data);
    console.log("Respuesta cruda de la API:", response);  
    // Format the data for the chart
    const chartData = response.items.flatMap(item => [
      { time: item.label, value: item.api, type: t("chart.api") },  
      { time: item.label, value: item.platform, type: t("chart.platform") },       
    ]);

    // let responseData = [];
    // response.items.forEach(x => {
    //   let time = x.label;
    //   x.itemsMode.forEach(y => {
    //     responseData.push({type: y.mode, value: y.countMessageMode, time: time})
    //   });
    // });

    //let totalValue = response.totalMessages;
    console.log("Datos formateados para la gráfica:", chartData);
    const totalValue = response.totalMessages;
    return {chartData, totalValue}; 
  }

  // const annotations = [];
  // each(groupBy(data, 'time'), (values, k) => {
  //   const value = values.reduce((a, b) => a + b.value, 0);
  //   annotations.push({
  //     type: 'text',
  //     position: [k, value],
  //     content: `${value}`,
  //     style: {
  //       textAlign: 'center',
  //       fontSize: 14,
  //       fill: 'rgba(0,0,0,0.85)',
  //     },
  //     offsetY: -10,
  //      xField: 'time',
  //       yField: 'value',
  //   });
  // });

  const config = {
    data: chartData,
    xField: 'time',
    yField: 'value',
    seriesField: 'type',
    isStack: true,
    color: [violetColor, violetColorOpacity],
    colorField: 'type', 
    scale: {
      color: {
        range: [purpleColor, violetColor],
      }
    }, 
    legend: {
      position: "top",
      flipPage: false
    },    
  };

  return (
    <div className={styles['dashboard-total']}>      
        <div className={styles['card-title-total']}>
          <p>{t("card-total-voice.label-title")}</p>
          {/* <HelperButton
            message={t("card-total-voice.tooltip-title")}
            color={getGradientColor(purpleColor)}
          /> */}
        </div>        
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-body']}>
              {/* <p>{numberFormat(total, 0)} <span>{t("card-total-voice.label-subtitle")}</span></p> */}
              {console.log("Datos pasados al gráfico:", chartData)}
              <Line height={300} {...config} />
            </div>            
          </Spin>
        </div>
    
    </div>
  );
}
 
export default TotalVoice;










// import { useContext, useState } from "react";
// import CardCustom from "../../../CardCustom/CardCustom";
// import HelperButton from "../../../HelperButton/HelperButton";
// import styles from "./TotalVoice.module.css";
// import { useEffect } from "react";
// import { Spin } from "antd";
// import { Line } from "@ant-design/charts";
// import { each, groupBy } from '@antv/util';
// import { getGradientColor, purpleColor, violetColor, violetColorOpacity, magentaColor, magentaColorOpacity } from "../../../../utils/colorsCss";
// import { useTranslation } from "react-i18next";
// import { numberFormat } from "../../../../utils/helpers";
// import DataApi from "../../../../Api/DataApi";
// import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST, CONSTANTUSERTYPE, ROLENEWDASBOARD} from "../../../../utils/const";
// import { AuthContext } from "../../../../contexts/authRedirect";

// const TotalVoice = ({filter}) => {

//   const [t] = useTranslation("home-company");
//   const {getDashboardChannels} = DataApi();
//   const { auth } = useContext(AuthContext);

//   const [loading, setLoading] = useState(true);
//   const [data, setData] = useState([]);
//   const [total, setTotal] = useState(0);

//   // Filtra los datos por cuenta, país, método, deliveryStatus y campaña
//   //  const applyFilters = (data) => {
//   //   let filteredData = [...data]; // Copiar los datos originales

//   //   // Filtrar por cuenta, si está presente en el filtro
//   //   if (filter.id && filter.id !== CONSTANTS.ACCOUNTDEFAULT.accountId) {
//   //     filteredData = filteredData.filter(item => item.accountId === filter.id);
//   //   }

//   //   // Filtrar por país, si está presente en el filtro
//   //   if (filter.country && filter.country !== 'ALL') {
//   //     filteredData = filteredData.filter(item => item.country === filter.country);
//   //   }

//   //   // Filtrar por método, si está presente en el filtro
//   //   if (filter.method && filter.method !== 'ALL') {
//   //     filteredData = filteredData.filter(item => item.method === filter.method);
//   //   }

//   //   // Filtrar por delivery status, si está presente en el filtro
//   //   if (filter.deliveryStatus && filter.deliveryStatus !== 'ALL') {
//   //     filteredData = filteredData.filter(item => item.deliveryStatus === filter.deliveryStatus);
//   //   }

//   //   // Filtrar por campaña, si está presente en el filtro
//   //   if (filter.campaign && filter.campaign !== 'ALL') {
//   //     filteredData = filteredData.filter(item => item.campaign === filter.campaign);
//   //   }

//   //   return filteredData;
//   // };

//   // useEffect(() => {
//   //   setLoading(true);
//   //   getTotalVoiceData(filter)
//   //   .then(response => {
//   //     setLoading(false);
//   //     const {chartData, totalValue} = response;
//   //       // Aplicar filtros (cuenta, país, método, deliveryStatus, campaña) sin alterar la fecha
//   //       const filteredData = applyFilters(chartData);
//   //       setData(filteredData);
//   //     setData(chartData);
//   //     setTotal(totalValue);
//   //   })
//   //   .catch(error => {
//   //     console.error(error);
//   //     setLoading(false);
//   //   })
    
//   // }, [filter])

//   useEffect(() => {
//     if(filter && filter.id) {
//       setLoading(true);

//       getTotalVoiceData()
//       .then(response => {
//         setLoading(false);

//         const {chartData, totalValue} = response;

//         setData(chartData);
//         setTotal(totalValue);
//       })
//       .catch(error => {
//         console.error(error);
//         setLoading(false);
//       })
//     }    
//   }, [filter])

//   const getTotalVoiceData = async () => {
//     /*let campaigns = [];
//     let accounts = [];    
//     let deliveryStatus = [];
//     let method = [];
//     let countries = [];
//     campaigns.push(filterValues.campaign);
//     accounts.push(filterValues.id);
//     deliveryStatus.push(filterValues.deliveryStatus);
//     method.push(filterValues.method);
//     countries.push(filterValues.country);*/
    
    
//     let data = {
//       CompanyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
//       AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
//       ChannelId: CHANELLIST.VOICE.key, 
//       // accounts: accounts,
//       // campaigns: campaigns,
//       // deliveryStatus :deliveryStatus,
//       // method :method,
//       // countries :countries,
//     }
  

//     //let userType = filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? CONSTANTUSERTYPE.COMPANY.key : CONSTANTUSERTYPE.ACCOUNT.key;
//     const userType = ROLENEWDASBOARD.COMPANY_ADMIN.key
//     let response = await getDashboardChannels(CHANELLIST.VOICE.value, userType, filter.time, data);
   

//     // Format the data for the chart
//       // Format the data for the chart
//       const chartData = response.items.flatMap(item => [
//         { time: item.label, value: item.api, type: "API" },
//         { time: item.label, value: item.platform, type: "Platform" },      
//       ]);
    
//     /*let responseData = [];
//     response.items.forEach(x => {
//       let time = x.label;
//       x.itemsMode.forEach(y => {
//         responseData.push({type: y.mode, value: y.countMessageMode, time: time})
//       });
//     });*/

//     let totalValue = response.totalMessages;
//     return {chartData, totalValue}; 
//   }
  


//   const config = {
//     data,
//     xField: 'time',
//     yField: 'value',
//     seriesField: 'type',
//     isStack: true,
//     color: [violetColor, violetColorOpacity], 
//     colorField: 'type', 
//     scale: {
//       color: {
//         range: [magentaColor, magentaColorOpacity],        
//       }
//     },
//     legend: {
//       position: "top",
//       flipPage: false
//     },    
//   };

//   return (
//     <div className={styles['dashboard-total']}>      
//       <div className={styles['card-title-total']}>
//         <p>{t("card-total-voice.label-title")}</p>  
//         {/* <HelperButton
//           message={t("card-total-voice.tooltip-title")}
//           color={getGradientColor(purpleColor)}
//         /> */}
//       </div>
//       <div className={styles['dashboard-card-spinner']}>
//         <Spin spinning={loading}>
//           <div className={styles['dashboard-card-body']}>
//             {/* <p>{numberFormat(total, 0)} <span>{t("card-total-voice.label-subtitle")}</span></p> */}
//             <Line height={300} {...config} />
//             </div>            
//           </Spin>
//         </div>
//     </div>
//   );
// }
 
// export default TotalVoice;
