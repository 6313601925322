import { useState, useContext  } from "react";
import CardCustom from "../../../CardCustom/CardCustom";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./TotalVoice.module.css";
import { useEffect } from "react";
import { Spin } from "antd";
import { Line } from "@ant-design/charts";
import DataApi from "../../../../Api/DataApi";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST, CONSTANTUSERTYPE, ROLENEWDASBOARD } from "../../../../utils/const";
import { AuthContext } from "../../../../contexts/authRedirect";
import { each, groupBy } from '@antv/util';
import { getGradientColor, violetColor, violetColorOpacity, purpleColor, notificationColor} from "../../../../utils/colorsCss";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../../../../utils/helpers";
import Subfilter from "../SubFilter/SubFilter";

const TotalVoice = ({filter, filterValues}) => {
  useEffect(() => {
    console.log("Valores recibidos en TotalSms:", filterValues);
}, [filterValues]); // Ejecutar cuando filterValues cambie

const [chartData, setChartData] = useState([]);
const [totalValue, setTotalValue] = useState(0);

  const  { t, i18n }  = useTranslation("home-carrier");
  const {getDashboardChannels} = DataApi();
  const { auth } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
        setLoading(true);

        try {
            if (filterValues.length === 0) {
                console.log("Cargando datos totales sin filtros...");
                const { chartData, totalValue } = await getTotalVoiceData([]);
                setChartData(chartData);
                setTotalValue(totalValue);
            } else {
                console.log("Cargando datos con filtros:", filterValues);
                const { chartData, totalValue } = await getTotalVoiceData(filterValues);
                setChartData(chartData);
                setTotalValue(totalValue);
            }
        } catch (error) {
            console.error("Error al cargar los datos:", error);
        } finally {
            setLoading(false);
        }
    };

    fetchData();
}, [filterValues, filter,  i18n.language]);  

  const getTotalVoiceData = async (filters = []) => {
    let data = {
      CompanyId: filter.id === CONSTANTS.COMPANYDEFAULT.companyId ? null : filter.id,
      AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
      ChannelId: CHANELLIST.VOICE.key, 
      companies: filterValues.find(f => f.field === "Companies")?.value || [],
      campaigns: filterValues.find(f => f.field === "Campaign")?.value || [],
      countries: filterValues.find(f => f.field === "Country")?.value || [],
      methods: filterValues.find(f => f.field === "Method")?.value || [],
      deliveryStatus: filterValues.find(f => f.field === "Delivery Status")?.value || [],      
    }
    
    //let userType = filter.id === CONSTANTS.COMPANYDEFAULT.companyId ? CONSTANTUSERTYPE.CARRIER.key : CONSTANTUSERTYPE.COMPANY.key;
    const userType = ROLENEWDASBOARD.ADMIN.key
    let response = await getDashboardChannels(CHANELLIST.VOICE.value, userType, filter.time, data);
    console.log("Respuesta cruda de la API:", response);   
    // Format the data for the chart
       const chartData = response.items.flatMap(item => [
        { time: item.label, value: item.api, type: t("chart.api") },  
        { time: item.label, value: item.platform, type: t("chart.platform") },   
      ]);
       
    
    // let responseData = [];
    // response.items.forEach(x => {
    //   let time = x.label;
    //   x.itemsMode.forEach(y => {
    //     responseData.push({type: y.mode, value: y.countMessageMode, time: time})
    //   });
    // });

    //let totalValue = response.totalMessages;
    console.log("Datos formateados para la gráfica:", chartData);
    const totalValue = response.totalMessages;
    return {chartData, totalValue}; 
  }

  
  const config = {
    data: chartData,
    xField: 'time',
    yField: 'value',
    seriesField: 'type',
    isStack: true,   
    colorField: 'type', 
    scale: {
      color: {
          range: [purpleColor, violetColor],
      }
    },   
    legend: {
      position: "top",
      flipPage: false
    },
    
  };

  return (
    <div className={styles['dashboard-total']}> 
        <div className={styles['card-title-total']}>
          <p>{t("card-total-voice.label-title")}</p>
          {/* <HelperButton
            message={t("card-total-voice.tooltip-title")}
            color={getGradientColor(purpleColor)}
          /> */}
        </div>   
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-body']}>
              {/* <p>{numberFormat(total, 0)} <span>{t("card-total-voice.label-subtitle")}</span></p> */}
              {console.log("Datos pasados al gráfico:", chartData)}
              <Line height={300} {...config} />
            </div>            
          </Spin>
        </div>      
    </div>
  );
}
 
export default TotalVoice;