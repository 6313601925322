import TotalCardVoice from "./TotalCardVoice/TotalCardVoice";
import TotalCardWhatsapp from "./TotalCardWhatsapp/TotalCardWhatsapp";
import TotalCardRcs from "./TotalCardRcs/TotalCardRcs";
import TotalCardSms from "./TotalCardSms/TotalCardSms";

import styles from "./TotalCards.module.css";

const TotalCards = ({filter}) => {
  return (
    <div className={styles['dashboard-card-container']}>     
         <TotalCardSms filter={filter}/>
         <TotalCardVoice filter={filter}/>
         <TotalCardWhatsapp filter={filter}/>
         <TotalCardRcs filter={filter}/>
    </div>
  );
};

export default TotalCards;
