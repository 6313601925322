import { useState, useEffect, useContext } from "react";
import styles from "./TotalCardSms.module.css";
import { Spin } from "antd";
import DataApi from "../../../../../Api/DataApi";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST, CONSTANTUSERTYPE, ROLENEWDASBOARD } from "../../../../../utils/const";
import { AuthContext } from "../../../../../contexts/authRedirect";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../../../../../utils/helpers";

const TotalCardSms = ({ filter }) => {
  
  const [t] = useTranslation("home-company");
  const { getDashboardTotal } = DataApi();
  const { auth } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  // Load data when the filter changes
  useEffect(() => {
    setLoading(true);
    getTotalSmsData()
      .then(response => {
        setLoading(false);
        const { totalValue } = response;
        setTotal(totalValue); // Save the total number of SMS messages
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [filter]);

  // Obtain data from the API for SMS
  const getTotalSmsData = async () => {
    console.log("filter:", filter);
    let data = {
      CompanyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
      AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
      //StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      //EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null,
    };

    
    const userType = ROLENEWDASBOARD.USER.key
    //let userType = filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? CONSTANTUSERTYPE.COMPANY.key : CONSTANTUSERTYPE.ACCOUNT.key;
    let response = await getDashboardTotal(userType, filter.time, data);
    

    // Return the processed data
    let totalValue = response.totalMessagesSms; // Total messages SMS
    return { totalValue };
  };

  return (
    <div className={styles['dashboard-card']}>
      <div className={styles['dashboard-card-spinner']}>
        <Spin spinning={loading}>
          <div className={styles['dashboard-card-total']}>
            <p className={styles['dashboard-card-title']}>{t("card-total.total-sms")}</p>
            <p className={styles['dashboard-card-number']}>{total}</p> 
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default TotalCardSms;
