import { useContext, useState } from "react";
import styles from "./TotalCardVoice.module.css";
import { useEffect } from "react";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../../../../../utils/helpers";
import DataApi from "../../../../../Api/DataApi";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST, CONSTANTUSERTYPE, ROLENEWDASBOARD } from "../../../../../utils/const";
import { AuthContext } from "../../../../../contexts/authRedirect";

const TotalCardVoice = ({filter, filterValues, onTotalUpdate}) => {

  const [t] = useTranslation("home-company");
  const {getDashboardChannels} = DataApi();
  const { auth } = useContext(AuthContext);

  const [loading, setLoading] = useState(true); 
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setLoading(true);

    getTotalVoiceData()
    .then(response => {
      setLoading(false);
      const {totalValue} = response;
      setTotal(totalValue);
      console.log("Total calculado para Voice:", totalValue);
       // Notificar al padre con el total calculado
       /*if (onTotalUpdate) {
        onTotalUpdate(totalValue);
      }*/
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    })
    
  }, [filter, filterValues])

  const getTotalVoiceData = async () => {
    let data = {
      CompanyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
      AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
      ChannelId: CHANELLIST.VOICE.key,  
      companies: filterValues?.find(f => f.field === "Companies")?.value || [],
      countries: filterValues?.find(f => f.field === "Country")?.value || [],
      campaigns: filterValues?.find(f => f.field === "Campaign")?.value || [],
      methods: filterValues?.find(f => f.field === "Method")?.value || [],
      deliveryStatus: filterValues?.find(f => f.field === "Delivery Status")?.value || [],
      //StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      //EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null
    }
    
    const userType = ROLENEWDASBOARD.COMPANY_ADMIN.key
    //let userType = filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? CONSTANTUSERTYPE.COMPANY.key : CONSTANTUSERTYPE.ACCOUNT.key;
    let response = await getDashboardChannels(CHANELLIST.SMS.value, userType, filter.time, data);
   

    /*let responseData = [];
    response.items.forEach(x => {
      let time = x.label;
      x.itemsMode.forEach(y => {
        responseData.push({type: y.mode, value: y.countMessageMode, time: time})
      });
    });*/

    let totalValue = response.totalMessages;
    return {totalValue}; 
  }

 
  return (
    <div className={styles['dashboard-card']}>     
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-total']}>
              <p className={styles['dashboard-card-title']}>{t("card-total.total-voice")}</p>
              <p className={styles['dashboard-card-number']}>{total}</p>             
            </div>            
          </Spin>
        </div>    
    </div>
  );
}
 
export default TotalCardVoice;