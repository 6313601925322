import styles from "./BestPracticeButton.module.css";
import { faInfoCircle, faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from "react-i18next";
import CONSTANTS, { embedded_best_practice_url } from "../../utils/const";

const handleDownload = () => {
  const fileUrl = embedded_best_practice_url
  window.open(fileUrl, '_blank');
};

const BestPracticeButton = () => {

  const [t] = useTranslation("best-practice");

  return (
    <div className={styles['container']}>
      <a target="_blank" onClick={handleDownload}><FontAwesomeIcon style={{marginRight: "10px", marginTop: "4px", fill:"none"}} icon={ faCircleInfo } /> {t("text-button")}</a>
    </div>
  );
}
 
export default BestPracticeButton;