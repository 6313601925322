import { useState, useEffect, useContext } from "react";
import styles from "./TotalCardRcs.module.css";
import { Spin } from "antd";
import DataApi from "../../../../../Api/DataApi";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST, CONSTANTUSERTYPE, ROLENEWDASBOARD } from "../../../../../utils/const";
import { AuthContext } from "../../../../../contexts/authRedirect";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../../../../../utils/helpers";


const TotalCardRcs = ({filter}) => {
  const [t] = useTranslation("home-company");
  const { getDashboardTotal } = DataApi();
  const { auth } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setLoading(true);
    getTotalSmsData()
      .then(response => {
        setLoading(false);
        const { totalValue } = response;        
        setTotal(totalValue);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [filter]);

  const getTotalSmsData = async () => {
    let data = {
      CompanyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
      AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,      
    };
    const userType = ROLENEWDASBOARD.ADMIN.key
    //let userType = filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? CONSTANTUSERTYPE.COMPANY.key : CONSTANTUSERTYPE.ACCOUNT.key;
    //let response = await getDashboardTotal (CHANELLIST.SMS.value, userType, filter.time, data);
    
    //let totalValue = response.totalMessagesRcs;
    return {  };
  };

  

  return (
    <div className={styles['dashboard-card']}>      
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-total']}>
              <p className={styles['dashboard-card-title']}>{t("card-total.total-rcs")}</p>
              <p className={styles['dashboard-card-number']}>0</p>
            </div>
          </Spin>
        </div>     
    </div>
  );
};

export default TotalCardRcs;
