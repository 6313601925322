import styles from "./AccountHome.module.css";
import AssignedCredit from "./AssignedCredit/AssignedCredit";
import OnHoldCredit from "./OnHoldCredit/OnHoldCredit";
import AvailableCredit from "./AvailableCredit/AvailableCredit";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ChannelSelect from "../../ChannelSelect/ChannelSelect";
import { useContext, useEffect, useState } from "react";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST } from "../../../utils/const";
import AccountApi from "../../../Api/AccountApi";
import { AuthContext } from "../../../contexts/authRedirect";
import CompanyFilters from "../../DashboardFilters/CompanyFilters/CompanyFilters";
import SubFilter from "./SubFilter/SubFilter"
import TotalChannels from "./TotalChannels/TotalChannels";
import TotalCards from "./TotalCards/TotalCards";
import TotalSms from "./TotalSms/TotalSms";
import TotalVoice from "./TotalVoice/TotalVoice";
import TotalConversionRate from "./TotalConversionRate/TotalConversionRate";
import TotalCampaigns from "./TotalCampaigns/TotalCampaigns";
import LastCampaigns from "./LastCampaigns/LastCampaigns";
import SmsStatus from "./SmsStatus/SmsStatus";
import VoiceStatus from "./VoiceStatus/VoiceStatus";
import TotalCardsVoice from "./TotalCardsVoice/TotalCardsVoice";
import TotalCardsSms from "./TotalCardsSms/TotalCardsSms";
import AccountConsumption from "./AccountConsumption/AccountConsumption";
import ChannelExpenses from "./ChannelExpenses/ChannelExpenses";
import { filterdAccountsByUser } from "../../../utils/helpers";


const FILTERINITIALDATA = {channel: CHANELLIST.DEFAULT, id: CONSTANTS.ACCOUNTDEFAULT.companyId, time: CONSTANPERIODREPORTLIST.CURRENTWEEK.key, date: []};

const AccountHome = () => {

  const [t] = useTranslation("home-company");
  const [ channelSelected, setChannelSelected ] = useState(CHANELLIST.DEFAULT);
  const [ accounts, setAccounts]  = useState([]);
  const [ filter, setFilter ] = useState(FILTERINITIALDATA)
  
  const { GetAccountsByCompanyId } = AccountApi();
  const { auth } = useContext(AuthContext);
  const shouldShowCollapse = channelSelected.key === CHANELLIST.SMS.key || channelSelected.key === CHANELLIST.VOICE.key;
  const [filterValues, setFilterValues] = useState([]);
  const [totals, setTotals] = useState({ sms: 0, voice: 0 });
  const [currentTotal, setCurrentTotal] = useState(0);

  /*useEffect(() => {
    getAccountsProccess(auth.companyId)
      .then(response => {
        if(response) {
          setAccounts(response);
          setFilter({...filter, account: response[0].accountId})
        }
      })
  }, [])*/

  useEffect(() => {
    getAccountsProccess(auth.companyId)
      .then(response => {
        if(response) {
          response.unshift(CONSTANTS.ACCOUNTDEFAULT);
          setAccounts(response);
        }
      })
  }, [])


  const onSelectChannel = (channel) => {
    setChannelSelected(channel);
    filter.channel = channel;
    setFilter(filter);
  }

  const getAccountsProccess = async (companyId) => {
    let accountByCompany = await GetAccountsByCompanyId(companyId);
    let accountByUser =  filterdAccountsByUser(accountByCompany, auth.userAccounts, auth.role);
    return accountByUser
  }

  const onChangeFilter = (filter) => {
    filter.channel = channelSelected;
    setFilter(filter);
  }

  const handleFilterChange = (values) => {
    console.log("Filtros recibidos en CarrierHome:", values);
    setFilterValues(values); // Actualizar el estado con los nuevos filtros
  };

  const handleTotalUpdate = (type, total) => {
    setTotals((prevTotals) => ({
      ...prevTotals,
      [type]: total, // Actualiza dinámicamente el tipo (sms, voice, etc.) con su total
    }));
  };

  useEffect(() => {
    console.log("Totales actualizados:", totals);
  }, [totals]);

  const render = () => {
    switch (channelSelected.key) {
      case CHANELLIST.DEFAULT.key:
        return <>
            <TotalChannels filter={filter} onTotalsUpdate={(newTotals) => setTotals(newTotals)}/>
            <TotalCards filter={filter}/>
            {/* <TotalSms filter={filter}/>
            <TotalVoice filter={filter}/>
            <TotalConversionRate filter={filter}/>
            <TotalCampaigns filter={filter} />
            <LastCampaigns filter={filter} />
            <SmsStatus filter={filter} />
            <VoiceStatus filter={filter} />
            <AccountConsumption filter={filter}/>
            <ChannelExpenses filter={filter} /> */}
          </>;
      case CHANELLIST.VOICE.key:
        return <>
            <TotalVoice filter={filter} filterValues={filterValues}/>
            <TotalCardsVoice filter={filter} filterValues={filterValues} onTotalUpdate={(total) => handleTotalUpdate("voice", total)} />
            {/* <LastCampaigns filter={filter} />
            <VoiceStatus filter={filter} />
            <ChannelExpenses filter={filter} /> */}
          </>; 
      case CHANELLIST.SMS.key:
        return <>
            <TotalSms filter={filter} filterValues={filterValues}/>
            <TotalCardsSms filter={filter} filterValues={filterValues} onTotalUpdate={(total) => handleTotalUpdate("sms", total)} />
            {/* <TotalConversionRate filter={filter}/>
            <LastCampaigns filter={filter} />
            <SmsStatus filter={filter} />
            <ChannelExpenses  filter={filter} /> */}
          </>;     
      default:
        return <></>
    }
  }

  return (
    <div className={styles['dashboard-home']}>
      <div className={styles['dashboard-header']}>
        <div className={styles['dashboard-header-cards']}>
          <AssignedCredit filter={filter} />
          <OnHoldCredit filter={filter}/>   
          <AvailableCredit filter={filter}/>       
        </div>
        {/* <div className={styles['dashboard-header-buttons']}>
          <Link to="/Campaigns/Voz" className={styles['dashboard-header-button']}>{t("dashboard-header-buttons.voice-campaign")}</Link>
          <Link to="/Campaigns/BulkCampaign" className={styles['dashboard-header-button']}>{t("dashboard-header-buttons.sms-campaign")}</Link>
        </div> */}
      </div>
      <div className={styles['dashboard-menu']}>
        <ChannelSelect onSelect={onSelectChannel}/>
        <CompanyFilters accounts={accounts} onChangeFilter={onChangeFilter} />
      </div>
      <SubFilter channelSelected={channelSelected} 
              shouldShowCollapse={shouldShowCollapse} 
              onFilterChange={handleFilterChange}
              totals={totals}/>
      <div className={styles['dashboard-body']}>
      {
        render()
      }
      </div>
    </div>
  );
}
 
export default AccountHome;