import { useContext, useState } from "react";
import styles from "./DeliveryStatusSms.module.css";
import { useEffect } from "react";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST, CONSTANTUSERTYPE, ROLENEWDASBOARD} from "../../../../../utils/const";
import { Link } from "react-router-dom";
import { numberFormat } from "../../../../../utils/helpers";
import DataApi from "../../../../../Api/DataApi";
import { AuthContext } from "../../../../../contexts/authRedirect";

const SmsStatus = ({filter, filterValues}) => {

  const [t] = useTranslation("home-company");
  const {getDashboardChannels} = DataApi();
  const { auth } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [successful, setSuccessfull] = useState(0);
  const [error, setError] = useState(0);
  const [convertion, setConvertion] = useState(0);
  const [period, setPiriod] = useState("");
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setLoading(true);

    getDeliverySms()
    // .then(response => {
    //   setLoading(false);
    //   const {successfulValue, errorValue, convertionValue} = response;
    //   setSuccessfull(successfulValue);
    //   setError(errorValue);
    //   setConvertion(convertionValue);

    //   if(filter && filter.time) {
    //     let resultPeriod = CONSTANTS.LIST.PERIODREPORT.find(x => x.key === filter.time);
    //     setPiriod(resultPeriod.value);
    //   }
    // })
    // .catch(error => {
    //   console.error(error);
    //   setLoading(false);
    // })

    
    .then(response => {
      setLoading(false);
      const {totalValue} = response;
      setTotal(totalValue);
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    })
    
  }, [filter, filterValues])

  const  getDeliverySms = async () => {
    let data = {
      CompanyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
      AccountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
      ChannelId: CHANELLIST.SMS.key, 
      companies: filterValues?.find(f => f.field === "Companies")?.value || [],
      countries: filterValues?.find(f => f.field === "Country")?.value || [],
      campaigns: filterValues?.find(f => f.field === "Campaign")?.value || [],
      methods: filterValues?.find(f => f.field === "Method")?.value || [],
      deliveryStatus: filterValues?.find(f => f.field === "Delivery Status")?.value || [], 
      //ChannelId: "sms"
      //StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      //EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null
    };

    // Validation to check if ChannelId is defined
  if (!data.ChannelId) {
    
    return { successfulValue: 0, errorValue: 0, convertionValue: 0 };  // Returns default values if ChannelId is missing
  }

 

    // try {
    //   const responseData = await getDashboardChannels(filter?.time, data);
    //   return {
    //     successfulValue: responseData?.successful || 0,
    //     errorValue: responseData?.error || 0,
    //     convertionValue: responseData?.conversionRate || 0,
    //   };
    // } catch (error) {
    //   console.error("Error en getDeliverySms:", error);
    //   return { successfulValue: 0, errorValue: 0, convertionValue: 0 };
    // }    
    //let userType = filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? CONSTANTUSERTYPE.COMPANY.key : CONSTANTUSERTYPE.ACCOUNT.key;
    const userType = ROLENEWDASBOARD.USER.key
    let response = await getDashboardChannels(CHANELLIST.SMS.value, userType, filter.time, data);
    let totalValue = response.totalMessages;
    return {totalValue}; 
  }

  return (
    <div className={styles['dashboard-delivery']}>
       <p className={styles['dashboard-delivery-title']}>{t("card-sms-status.label-title")}</p>
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-delivery-content']}>             
              <div className={styles['dashboard-delivery-successful']}>
                <p className={styles['dashboard-delivery-title-card']}>{t("card-voice-status.label-successful")}</p>
                <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-succesfull']}`}>{total}</div>
              </div>
              <div className={styles['dashboard-delivery-failed']}>
                <p className={styles['dashboard-delivery-title-card']}>{t("card-voice-status.label-error")}</p>
                <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-error']}`}>0</div>
              </div>
              <div className={styles['dashboard-delivery-convertion-rate']}>
                <p className={styles['dashboard-delivery-title-card']}>{t("card-voice-status.label-convertion")}</p>
                <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-convertion']}`}>100%</div>
              </div>
            </div>
          </Spin>
        </div>   
    </div>
  );
}
 
export default SmsStatus;