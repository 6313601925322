import TotalCardVoice from "./TotalCardVoice/TotalCardVoice";
import DeliveryStatusVoice from "./DeliveryStatusVoice/DeliveryStatusVoice";

import styles from "./TotalCardsVoice.module.css";

const TotalCardsVoice = ({filter, filterValues, handleTotalUpdate}) => {
  return (
    <div className={styles['dashboard-card-container']}> 
         <TotalCardVoice filter={filter} filterValues={filterValues} onTotalUpdate={(total) => handleTotalUpdate("voice", total)}/> 
         <DeliveryStatusVoice filter={filter} filterValues={filterValues}/>
    </div>
  );
};

export default TotalCardsVoice;
