import useAuth from "../Hooks/useAuth";
import { reportRequest} from "../utils/authConfig";

const ReportApi = () => {
  const { passTokenToApi } = useAuth();
  return {
    GetFiltersReport: async (companyId, filter) => {
      const accessToken = await passTokenToApi(reportRequest);
      try {
        if (accessToken) {

          var myHeaders = new Headers();

          const bearer = `Bearer ${accessToken}`;
          myHeaders.append("Authorization", bearer);
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify(filter);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          const resource = `/api/Report/Filters/Company/${companyId}`;
          const url = `${process.env.REACT_APP_REPORT_API_URL}${resource}`;
          
          let response = await fetch(url, requestOptions);
          
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting list filters. Status Code: ${response.status}`);
          }
    
          let filters = await response.json();
          return filters;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting report filters.");
      }
    },
    GenerateReportMessages: async (companyId, email, report) => {
      const accessToken = await passTokenToApi(reportRequest);
      try {
        if (accessToken) {

          var myHeaders = new Headers();

          const bearer = `Bearer ${accessToken}`;
          myHeaders.append("Authorization", bearer);
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify(report);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          const resource = `/api/Company/${companyId}/User/${email}/Report`;
          const url = `${process.env.REACT_APP_REPORT_API_URL}${resource}`;
    
          let response = await fetch(url, requestOptions);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred genarating report. Status Code: ${response.status}`);
          }
    
          let message = await response.text();
          return message;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting report filters.");
      }
    },
    GenerateCampaignReport: async (companyId, email, campaignId, channel) => {
      const accessToken = await passTokenToApi(reportRequest);
      try {
        if (accessToken) {

          var myHeaders = new Headers();

          const bearer = `Bearer ${accessToken}`;
          myHeaders.append("Authorization", bearer);
          myHeaders.append("Content-Type", "application/json");

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          const resource = `/api/Company/${companyId}/User/${email}/Report/Campaign/${campaignId}/Channel/${channel}`;
          const url = `${process.env.REACT_APP_REPORT_API_URL}${resource}`;
    
          let response = await fetch(url, requestOptions);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred genarating report. Status Code: ${response.status}`);
          }
    
          let message = await response.text();
          return message;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting report filters.");
      }
    },  
    GetListReport: async (email) => {
      const accessToken = await passTokenToApi(reportRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/User/${email}/Reports`;
          const url = `${process.env.REACT_APP_REPORT_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred genarating report. Status Code: ${response.status}`);
          }
    
          let reports = await response.json();
          return reports;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting report filters.");
      }
    },
    getReportDashboard: async (userType, time, report) => {
      const accessToken = await passTokenToApi(reportRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          const bearer = `Bearer ${accessToken}`;
          myHeaders.append("Authorization", bearer);
          myHeaders.append("Content-Type", "application/json");
          console.log("Datos enviados a la API (report):", report);
          var raw = JSON.stringify(report);
          console.log("Cuerpo enviado al API (report):", raw);
    
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: raw,
          };
    
          const resource = `/api/Dashboard/Report/${userType}/Time/${time}`;
          const url = `${process.env.REACT_APP_REPORT_API_URL}${resource}`;
          console.log("URL generada:", url);
          console.log("Encabezados enviados al API:", myHeaders);
    
          let response = await fetch(url, requestOptions);
          console.log("Respuesta completa de la API:", response);
    
          if (response.status !== 200) {
            const errorText = await response.text();
            console.error("Error de la API:", errorText);
            throw new Error(`An error occurred generating report. Status Code: ${response.status}`);
          }
    
          let message = await response.text();
          console.log("Cuerpo de la respuesta:", message);
          return message;
        } else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error("Error al llamar a la API:", error);
        throw new Error("An error occurred getting report filters.");
      }
    }
    
  }
}

export default ReportApi;


