import { faUpload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, Form, Input, Button, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';
import { getGradientColor, orangeColor } from '../../utils/colorsCss';
import styles from "./Feedback.module.css";
import CustomButton from '../Button/Button';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

const Feedback = ({open, onClose, visible, onSend}) => {

  const [ fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [t] = useTranslation("feedback");

  const props = {
    name: 'file',
    multiple: false,
    fileList,
    beforeUpload: file => {
      setFileList([file]);
      return true;
    },
    onRemove: () => {
      setFileList([]);
    }
  }

  const onCloseHandle = () => {
    setFileList([]);
    form.resetFields();
    onClose(false);
  }

  const onClick = () => {
    form.validateFields()
    .then(values => {

      let feedback = {
        subject: values.subject,
        message: values.message,
        file: fileList[0]
      }

      onSend(feedback);
      onCloseHandle();
    })
    .catch((info) => {
      console.info(info);
    });
  }

  return (
    <Drawer
      title={t("title")}
      styles={{ header: { backgroundColor: getGradientColor(orangeColor)} }}  
      placement="right"
      onClose={onCloseHandle}
      open={open}
      width={520}
      footer={
        <div className={styles['footer']}>
          <CustomButton onClick={onClick} type="primary" data-name="btnSave">
            {t("btn-ok")}
          </CustomButton>
          <CustomButton onClick={onCloseHandle} type="secondary" data-name="btnCancel">
            {t("btn-cancel")}
          </CustomButton>
        </div>
      }
    >
      <Form
        data-testid="drawer-content"
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
            initialState: false,
        }}
      >
        <Form.Item
          name="subject"
          label={t("label-subject")}
          rules={[
            {
              required: true,
              message: t("subject-validation")
            }
          ]}
        >
          <Input placeholder={t("placeholder-subject")} />
        </Form.Item>
        <Form.Item
          name="message"
          label={t("label-message")}
          rules={[
            {
              required: true,
              message: t("message-validation")
            }
          ]}
        >
          <TextArea rows={8} />
        </Form.Item>
        <Form.Item
          name="attachFile"
          label={t("label-file")}
        >
          <Dragger {...props}>
            {/* <Button
              className={styles['file-dragger-button']} shape="circle" icon={<FontAwesomeIcon icon={ faUpload } />} size="large"
            ></Button> */}
            <br />
            <br />
            <p className="ant-upload-text"><strong>{t("title-dragger")}</strong></p>
            <br />
            <p className="ant-upload-hint">
              {t("text-dragger")}
            </p>
            <br />
          </Dragger>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
 
export default Feedback;