import { useEffect, useState, useRef } from 'react';

const useFacebookLogin = () => {
  const [user, setUser] = useState(null);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const isSDKInitialized = useRef(false);

  useEffect(() => {

    if (document.getElementById('facebook-jssdk')) {
      isSDKInitialized.current = true;
      return;
    }

    if (isSDKInitialized.current) return;

    window.fbAsyncInit = function() {
      window.FB.init({
        appId      : process.env.REACT_APP_WA_APP_ID,
        cookie     : true,
        xfbml      : true,
        version    : process.env.REACT_APP_WA_SDK_VERSION
      });
      window.FB.AppEvents.logPageView();
      isSDKInitialized.current = true;

      // Dev note: You can use this one to determine if person is already logged before start.
      // window.FB.getLoginStatus(
      //   function(response) {
      //     console.log("[UseFacebookLogin] fbAsyncInit FB.getLoginStatus response", {response});
      // }); 
    };

    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  const handleFacebookLogin = () => {
    setButtonEnabled(false)
    window.FB.login(
      function(response) {
        if (response.authResponse && response.status==='connected') {
          const code = response.authResponse.code;
           // Imprimir el valor de response.authResponse.code
          console.log("Respuesta de code:", response.authResponse.code);
          setUser(code);
          setButtonEnabled(true);
        } else {
          console.error("[UseFacebookLogin] FB.login response", {response});
          setUser("_error_");
          // TODO: For now all invalid status means an error but we can handle error message using below code as reference.
          // if (response.status === 'not_authorized' || response.status === 'unknown') {
          //   setUser("_error_");
          // } else if (response.error && response.error.code === 4) {
          //   // Error code 4 corresponds to rate limit exceeded
          //   setUser("_rate_limit_exceeded_");
          // } else {
          //   setUser("_error_");
          // }
        }
      setButtonEnabled(true);
    }, {
        config_id: process.env.REACT_APP_WA_CONFIG_ID,
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          setup: {
            // Prefilled data can go here. Data personal
          },
          featureType: '',
          sessionInfoVersion: '2',
        },
        scope: 'public_profile,email',
        auth_type: 'reauthenticate',
        return_scopes: true,
      }
    );
    
    // Dev note: An example to check LoginState and force roundtrip
    // More details: https://developers.facebook.com/docs/facebook-login/web
    // window.FB.getLoginStatus(
    //   function(response) {
    //     console.log("[UseFacebookLogin] FB.getLoginStatus response", {response});
    // }, true); // force a roundtrip 
  };

  // For now we are cleanning up the state to manage facebookLogout
  const handleFacebookLogout = () => {
    setUser(null)
  }

  return { user, buttonEnabled, handleFacebookLogin, handleFacebookLogout }
};

// Dev notes: You can use Facebook SDK to logout for future implementation in case is required
// More details: https://developers.facebook.com/docs/facebook-login/web
/* 
 // CheckLogout
    window.FB.logout(
      function(response) {
        console.log("[UseFacebookLogin] FB.logout response", {response});
    });
*/

export default useFacebookLogin;