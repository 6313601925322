import { DatePicker, Input, Select } from "antd";
import styles from "./CampaignReports.module.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import CONSTANTS, { CONSTANPERIODREPORTLIST } from "../../utils/const";
import useSpinner from "../../Hooks/useSpinner";
import TYPES from "../../reducers/types";
import useHeaderOptions from "../../Hooks/useHeaderOptions";
import useAuth from "../../Hooks/useAuth";
import { useEffect } from "react";
import AccountApi from "../../Api/AccountApi";
import { dateFormat, filterdAccountsByUser, truncateString, notificationError } from "../../utils/helpers";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/pro-solid-svg-icons";
import TableCardCustom from "../../components/TableCardCustom/TableCardCustom";
import TableCardHeadCustom from "../../components/TableCardCustom/TableCardHeadCustom/TableCardHeadCustom";
import TableCardBodyCustom from "../../components/TableCardCustom/TableCardBodyCustom/TableCardBodyCustom";
import TableCardBodyTrCustom from "../../components/TableCardCustom/TableCardBodyTrCustom/TableCardBodyTrCustom";
import TableCardButtons from "../../components/TableCardCustom/TableCardButtons/TableCardButtons";
import dayjs from 'dayjs';
import DataApi from "../../Api/DataApi";
import ReportApi from "../../Api/ReportApi";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;
const { RangePicker } = DatePicker;

const CampaignReports = () => {

  const [reportName, setReportName] = useState("");
  const [isTimeCustom , setIsTimeCustom ] = useState(false);
  const [date , setDate ] = useState([]);
  const [time , setTime ] = useState();
  const [channel , setChannel ] = useState();
  const [accounts , setAccounts ] = useState([]);
  const [accountsSelected , setAccountsSelected ] = useState([]);
  const [reports , setReports ] = useState([]);

  const [t] = useTranslation("campaign-reports");
  const { auth } =  useAuth();
  const { headerOptionsDispacth } = useHeaderOptions();  
  const { spinnerDispacth } = useSpinner();

  const { GetAccountsByCompanyId } = AccountApi();
  const { GenerateReportMessages } = ReportApi();
  const { GetListReport } = ReportApi();

  useEffect(() => {

    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.DEFAULT_OPTION,
      payload: TYPES.HEADER_OPTIONS.DEFAULT_OPTION
    });

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
    });

    getInitialData()
      .then(response => {

        const {accountsFilter, listReports} = response;
        setAccounts(accountsFilter);
        setReports(listReports);

        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
        });
      }).catch(error => {
        console.error(error);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
        });
      });
  }, [])

  const getInitialData = async () => {
    let accountsByCompany = await GetAccountsByCompanyId(auth.companyId, auth.accountAccessToken);

    let accountByUser =  filterdAccountsByUser(accountsByCompany, auth.userAccounts, auth.role)
    let result = accountByUser.map(x => { return { label: x.name, value: x.accountId } });
    let listReports = await GetListReport(auth.userName);

    return { accountsFilter: result, listReports: listReports }
  } 

  const handleReportName = (e) => {
    let value = e.target.value;
    setReportName(value);    
  }

  const handlePeriod = (value) => {
    setTime(value);
    if(value === CONSTANPERIODREPORTLIST.CUSTOM.key) {
      setIsTimeCustom(true);
    } else { 
      setDate([])
      setIsTimeCustom(false);
    }
  }

  const handleDate = (value) => {
    setDate(value);
  }

  const handleChannel = (value) => {
    setChannel(value);
  }
  
  const getButtonDisable = () => {
    if (isTimeCustom) {
      return (reportName.length ===0 || !channel || date.length === 0 || accountsSelected.length === 0);
    } else {
      return (reportName.length ===0 || !channel || !time || accountsSelected.length === 0);
    }
  }

  const handleAccountsChange = (value) => {
    let newAccountsSelected = value.map(x => { return { label: x, value: x } });
    setAccountsSelected(newAccountsSelected);
  }

  const onGenerateReport = () => { 

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.REPORTMESSAGE }
    });

    

    
    generateReportProccess()
    .then(response => {

      setReportName("");
      setIsTimeCustom(false);
      setReports(response);


      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.REPORTMESSAGE }
      });
    })
    .catch(error => {
      console.error(error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.REPORTMESSAGE }
      });
      notificationError(error.message);
      console.error(error);
    });
  }

  const generateReportProccess = async () => {
    let report = {
      StartDate: isTimeCustom ? date[0].toDate() : null,
      EndDate: isTimeCustom ? date[1].toDate() : null,
      Accounts : accountsSelected.map(x => x.value),
      DatePickerId: time,
      NameReport: reportName,
      ChannelId : channel
    };

    await GenerateReportMessages(auth.companyId, auth.userName, report);
    let listReports = await GetListReport(auth.userName);
    return listReports;
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current >= dayjs().endOf('day');
  };

  return (
    <div className={styles['content']}>
      <div className={styles['content-card']}>
        <div className={styles['content-card-left']}>
          <div className={styles['form']}>
            <label>{t("filters.label-name-report")}</label>
            <Input
              placeholder={t("filters.placeholder-name-report")}
              onChange={handleReportName}
              value={reportName}
              style={{ width: '100%' }}
            />
          </div>
          <div className={styles['form']}>
            <label>{t("filters.label-chanel")}</label>
            <Select
              placeholder={t("filters.placeholder-chanel")}
              onChange={handleChannel}
              defaultValue={channel}
              style={{ width: '100%' }}
            >
              {
                CONSTANTS.LIST.CHANELS.map(item =>
                  <Option key={item.key} value={item.key}>{item.value}</Option>
                )
              }
            </Select>
          </div>
          <div className={styles['form']}>
            <label>{t("filters.label-time")}</label>
            <Select
              placeholder={t("filters.placeholder-time")}
              onChange={handlePeriod}
              defaultValue={time}
              style={{ width: '100%' }}
            >
              {
                CONSTANTS.LIST.PERIODREPORT.map(item =>
                  <Option key={item.key} value={item.key}>{item.value}</Option>
                )
              }
            </Select>
          </div>
          <div className={styles['form']}>
            <RangePicker 
              style={{ width: '100%' }} 
              disabled={!isTimeCustom}
              onChange={handleDate}
              disabledDate={disabledDate}
              value={date}
              format="MM/DD/YYYY"/>
          </div>
          <div className={styles['form']}>
            <label>{t("filters.label-select-account")}</label>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder={t("filters.placeholder-select-account")}
              options={accounts}
              disabled={accounts.length === 0}
              defaultValue={accountsSelected}
              onChange={handleAccountsChange}
            />
          </div>
          <div className={styles['form']}>
            <Button style={{margin: "0px"}} disabled={getButtonDisable()} type="primary" onClick={() => onGenerateReport()}>
              {t("filters.button-generate-report")}
            </Button>
          </div>
        </div>
      </div>
      <div className={styles['content-card']}>
        <div className={styles['content-card-right']}>
          <p className="yp-title">{t("list-reports.title")}</p>
          {
            reports.length > 0 && (
              <TableCardCustom>
                <TableCardHeadCustom>
                  <th>
                  </th>
                  <th>{t("filters.label-name-report")}</th>
                  <th>{t("list-reports.label-request-date")}</th>
                  <th>{t("list-reports.label-status")}</th>   
                </TableCardHeadCustom>
                <TableCardBodyCustom>
                {
                  reports.map((item, index) => (
                    <TableCardBodyTrCustom key={item.id}>
                      <td>
                        <TableCardButtons>
                          <a disabled={!item.uri} href={item.uri}><FontAwesomeIcon icon={ faFileDownload } id={`download-icon-${index}`} /></a>
                        </TableCardButtons>
                      </td>
                      <td>
                        {truncateString(item.name, 30)}
                      </td>
                      <td>
                        {dateFormat(item.creationDate)}
                      </td>                      
                      <td>
                        {item.status}
                      </td>
                    </TableCardBodyTrCustom>            
                ))}
                </TableCardBodyCustom>
              </TableCardCustom>
            )
          }
        </div>
      </div>
    </div>
  );
}
 
export default CampaignReports;