import { useTranslation } from "react-i18next";
import BillingSms from "./BillingSms/BillingSms";
import styles from "./CarrierHome.module.css";
import BillingVoice from "./BillingVoice/BillingVoice";
import BillingShortlink from "./BillingShortlink/BillingShortlink";
import { Link } from "react-router-dom";
import ChannelSelect from "../../ChannelSelect/ChannelSelect";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST } from "../../../utils/const";
import { useState, useContext, useEfect} from "react";
import CarrierFilters from "../../DashboardFilters/CarrierFilters/CarrierFilters";
import CompanyApi from "../../../Api/CompanyApi";
import { AuthContext } from "../../../contexts/authRedirect";
import { useEffect } from "react";
import SubFilter from "./SubFilter/SubFilter"
import TotalChannels from "./TotalChannels/TotalChannels";
import TotalCards from "./TotalCards/TotalCards";
import TotalSms from "./TotalSms/TotalSms";
import TotalVoice from "./TotalVoice/TotalVoice";
import TotalConversionRate from "./TotalConversionRate/TotalConversionRate";
import TotalCampaigns from "./TotalCampaigns/TotalCampaigns";
import CampaignsByCountry from "./CampaignsByCountry/CampaignsByCountry";
import CampaignConsumption from "./CampaignConsumption/CampaignConsumption";
import ChannelExpenses from "./ChannelExpenses/ChannelExpenses";
import TopCompanyConsumptions from "./TopCompanyConsumptions/TopCompanyConsumptions";
import TotalCardsVoice from "./TotalCardsVoice/TotalCardsVoice";
import TotalCardsSms from "./TotalCardsSms/TotalCardsSms";

const FILTERINITIALDATA = {channel: CHANELLIST.DEFAULT, id: CONSTANTS.COMPANYDEFAULT.companyId, time: CONSTANPERIODREPORTLIST.CURRENTWEEK.key, date: []};

const CarrierHome = () => {

  const [t] = useTranslation("home-carrier");
  const [ channelSelected, setChannelSelected ] = useState(CHANELLIST.DEFAULT);
  const [ companies, setCompanies]  = useState([]);
  const [ filter, setFilter ] = useState(FILTERINITIALDATA)

  const { GetCompanies } = CompanyApi();
  const { auth } = useContext(AuthContext);
  const shouldShowCollapse = channelSelected.key === CHANELLIST.SMS.key || channelSelected.key === CHANELLIST.VOICE.key;
  const [filterValues, setFilterValues] = useState([]);
  const [totals, setTotals] = useState({ sms: 0, voice: 0 });
  const [currentTotal, setCurrentTotal] = useState(0);
  
  useEffect(() => {
    getCompaniesProccess()
      .then(response => {
        if(response) {
          let responseMap = response.map(x => { return { companyId: x.companyId, name: x.companyName}});
          responseMap.unshift(CONSTANTS.COMPANYDEFAULT);
          setCompanies(responseMap);
        }
      })
  }, [])

  const getCompaniesProccess = async () => {
    let response = await GetCompanies();
    return response
  }

  const onSelectChannel = (channel) => {
    setChannelSelected(channel);
    filter.channel = channel;
    setFilter(filter);
  }

  const onChangeFilter = (filter) => {
    filter.channel = channelSelected;
    setFilter(filter);
  }

  const handleFilterChange = (values) => {
    console.log("Filtros recibidos en CarrierHome:", values);
    setFilterValues(values); // Actualizar el estado con los nuevos filtros
};

const handleTotalUpdate = (type, total) => {
  setTotals((prevTotals) => ({
    ...prevTotals,
    [type]: total, // Actualiza dinámicamente el tipo (sms, voice, etc.) con su total
  }));
};

useEffect(() => {
  console.log("Totales actualizados:", totals);
}, [totals]);

  const render = () => {
    switch (channelSelected.key) {
      case CHANELLIST.DEFAULT.key:
        return <>
            <TotalChannels filter={filter} onTotalsUpdate={(newTotals) => setTotals(newTotals)}/>
            <TotalCards filter={filter}/>
            {/* <TotalSms filter={filter}/>
            <TotalVoice filter={filter}/>
            <TotalConversionRate filter={filter}/>
            <TotalCampaigns filter={filter}/>
            <CampaignsByCountry filter={filter}/>
            <CampaignConsumption filter={filter}/>
            <ChannelExpenses filter={filter}/>
            <TopCompanyConsumptions filter={filter}/>  */}
          </>;
      case CHANELLIST.VOICE.key:
        return <>
            <TotalVoice filter={filter} filterValues={filterValues}/>
            <TotalCardsVoice filter={filter} filterValues={filterValues} onTotalUpdate={(total) => handleTotalUpdate("voice", total)}/>
            {/* <TotalCampaigns filter={filter}/>
            <ChannelExpenses filter={filter}/> */}
          </>; 
      case CHANELLIST.SMS.key:
        return <>
            <TotalSms filter={filter} filterValues={filterValues}/>
            <TotalCardsSms filter={filter} filterValues={filterValues} onTotalUpdate={(total) => handleTotalUpdate("sms", total)} />
            {/* <TotalConversionRate filter={filter}/>
            <TotalCampaigns filter={filter}/>
            <ChannelExpenses filter={filter}/>  */}
          </>;     
      default:
        return <></>
    }
  }

  return (
    <div className={styles['dashboard-home']}>
      <div className={styles['dashboard-header']}>
        <div className={styles['dashboard-header-cards']}>
          <BillingSms />
          <BillingVoice />
          <BillingShortlink />
        </div>
        {/* <div className={styles['dashboard-header-buttons']}>
          <Link to="/Companies" className={styles['dashboard-header-button']}>{t("dashboard-header-buttons.add-balance")}</Link>
        </div> */}
      </div>
      <div className={styles['dashboard-menu']}>
        <ChannelSelect onSelect={onSelectChannel}/>
        <CarrierFilters companies={companies} onChangeFilter={onChangeFilter}/>
      </div>
      <SubFilter filter={filter} 
                channelSelected={channelSelected}
                shouldShowCollapse={shouldShowCollapse} 
                onFilterChange={handleFilterChange}
                totals={totals}/>
      <div className={styles['dashboard-body']}>
      {
        render()
      }
      </div>
    </div>
  );
}
 
export default CarrierHome;